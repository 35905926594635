
import { defineComponent } from "vue";
import textImage from "@/components/TextAndImage.vue";
import backTop from "@/components/BackTopButton.vue";

export default defineComponent({
  name: "mission",
  components: {
    textImage,
    backTop,
  },
  data() {
    return {
      text: this.$t("mission-component.claim"),
      missions: [
        {
          title: this.$t("mission-component.connections-title"),
          text: this.$t("mission-component.connections-text"),
          image: "mission-connections.svg",
          isReverse: false,
        },
        {
          title: this.$t("mission-component.ideas-opportunities-title"),
          text: this.$t("mission-component.ideas-opportunities-text"),
          image: "mission-ideas-opportunities.svg",
          isReverse: true,
        },
        {
          title: this.$t("mission-component.learning-partners-title"),
          text: this.$t("mission-component.learning-partners-text"),
          image: "mission-mentoring.svg",
          isReverse: false,
        },
        {
          title: this.$t("mission-component.exchange-title"),
          text: this.$t("mission-component.exchange-text"),
          image: "mission-exchange.svg",
          isReverse: true,
        },
      ],
    };
  },
});
