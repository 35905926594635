
import { defineComponent } from "vue";
import { userInterface } from "@/models/UserInteface";

export default defineComponent({
  name: "userImage",
  props: {
    user: {
      type: Object as () => userInterface,
      required: true,
    },
  },
  computed: {
    getAvatar(): string {
      return this.user.avatar.includes("http")
        ? this.user.avatar
        : require("@/assets/images/users/" + this.user.avatar);
    },
  },
});
