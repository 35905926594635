
import { defineComponent } from "vue";
import emailjs from "@emailjs/browser";

export default defineComponent({
  name: "contactForm",
  data() {
    return {
      email: "",
      text: "",
      errorEnablerEmail: false,
      errorEnablerTextArea: false,
      errorEmail: "",
      errorTextArea: "",
      errorEmailEmpty: this.$t("contact-component.error-empty-field"),
      errorEmailFormat: this.$t("contact-component.error-email-format"),
      emailPlaceholder: this.$t("contact-component.email-placeholder"),
      textPlaceholder: this.$t("contact-component.text-placeholder"),
    };
  },
  methods: {
    onClick(field: string) {
      if (field === "email") {
        this.errorEnablerEmail = true;
      } else {
        this.errorEnablerTextArea = true;
      }
    },
    modifyErrorStringEmail(error: string) {
      this.errorEmail = error;
    },
    modifyErrorStringText() {
      this.errorTextArea = this.$t("contact-component.error-empty-field");
    },
    handleSubmit() {
      if (!this.errorEnablerEmail && !this.errorEnablerTextArea) {
        this.errorEnablerEmail = true;
        this.errorEnablerTextArea = true;
      }
      if (!this.errorEnablerEmail) {
        this.errorEnablerEmail = true;
      } else if (!this.errorEnablerTextArea) {
        this.errorEnablerTextArea = true;
      } else if (
        !this.validateEmail &&
        !this.validateTextArea &&
        this.errorEnablerEmail &&
        this.errorEnablerTextArea
      ) {
        try {
          emailjs.sendForm(
            process.env.VUE_APP_SERVICE_ID,
            process.env.VUE_APP_TEMPLATE_ID,
            this.$refs.formRef as HTMLFormElement,
            process.env.VUE_APP_USER_KEY
          );
        } catch (error) {
          console.log({ error });
        }
        this.email = "";
        this.text = "";
        this.errorEnablerEmail = false;
        this.errorEnablerTextArea = false;
      }
    },
  },
  computed: {
    validateEmail() {
      const EMAILREGEX =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
      if (!this.email && this.errorEnablerEmail) {
        this.modifyErrorStringEmail(this.errorEmailEmpty);
        return true;
      } else if (!EMAILREGEX.test(this.email) && this.errorEnablerEmail) {
        this.modifyErrorStringEmail(this.errorEmailFormat);
        return true;
      }
      return false;
    },
    validateTextArea() {
      if (!this.text && this.errorEnablerTextArea) {
        this.modifyErrorStringText();
        return true;
      }
      return false;
    },
  },
});
