
import { defineComponent } from "vue";
import { HeaderSection } from "./Header.vue";

export default defineComponent({
  name: "headerHamburger",
  props: {
    headerSections: {
      type: Array as () => HeaderSection[],
      required: true,
      default: () => [],
    },
  },
});
