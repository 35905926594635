
import { defineComponent } from "vue";

export default defineComponent({
  name: "footerComponent",
  data() {
    return {
      email: process.env.VUE_APP_CONTACT_EMAIL,
    };
  },
});
