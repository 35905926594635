
import { defineComponent } from "vue";
import socialButtons from "@/components/Socials.vue";

export default defineComponent({
  name: "socialAndContacts",
  components: {
    socialButtons,
  },
  data() {
    return {
      email: "contact@likeacoffee.com",
    };
  },
});
