import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ceeef5d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contacts component" }
const _hoisted_2 = { class: "section-header-div" }
const _hoisted_3 = { class: "contacts-content" }
const _hoisted_4 = { class: "form-container" }
const _hoisted_5 = { class: "social-container" }
const _hoisted_6 = { class: "motto-container" }
const _hoisted_7 = { class: "subtitle-text-grey-10" }
const _hoisted_8 = { class: "social-component" }
const _hoisted_9 = { class: "back-top-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sectionHeader = _resolveComponent("sectionHeader")!
  const _component_contactForm = _resolveComponent("contactForm")!
  const _component_socialAndContacts = _resolveComponent("socialAndContacts")!
  const _component_socialButtons = _resolveComponent("socialButtons")!
  const _component_backTop = _resolveComponent("backTop")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_sectionHeader, {
        text: _ctx.text,
        textColor: 'grey-10'
      }, null, 8, ["text"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_contactForm)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_socialAndContacts)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("contact-component.last-motto")), 1)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_socialButtons)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_backTop)
    ])
  ]))
}