
import { defineComponent } from "vue";
import sectionHeader from "@/components/SectionHeader.vue";
import socialAndContacts from "@/components/SocialAndContacts.vue";
import backTop from "@/components/BackTopButton.vue";
import contactForm from "@/components/ContactsForm.vue";
import socialButtons from "@/components/Socials.vue";

export default defineComponent({
  name: "contacts",
  components: {
    sectionHeader,
    socialAndContacts,
    backTop,
    contactForm,
    socialButtons,
  },
  data() {
    return {
      text: this.$t("contact-component.contact-us"),
    };
  },
});
