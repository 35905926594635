
import { defineComponent } from "vue";

export default defineComponent({
  name: "socialButtons",

  data() {
    return {
      socials: [
        {
          imgsrc: "discord-mark-white.svg",
          link: process.env.VUE_APP_DISCORD_LINK,
        },
        {
          imgsrc: "facebook-icon.svg",
          link: "https://www.facebook.com/Likeacoffeeteam/",
        },
        {
          imgsrc: "youtube-icon.svg",
          link: "https://www.youtube.com/channel/UCk6g_6WdotfWzhzyeF2MYjw?view_as=subscriber",
        },
        {
          imgsrc: "linkedin-icon.svg",
          link: "https://www.linkedin.com/company/like-a-coffee/?challengeId=AQE4Ca3IcuZ10gAAAX-MvRzg-QW9m8uRn1KLkj2d88XmD_Cf2KjbfJc3yuIqUdIFYA8wZeFkY7ds5tsbrLIMoS8O3hDlUQnwjQ&submissionId=be0efe96-4081-dc16-7267-d2b8faf79637",
        },
      ],
    };
  },

  methods: {
    getImg(imgsrc: string): string {
      return imgsrc.includes("http")
        ? imgsrc
        : require("@/assets/images/" + imgsrc);
    },
  },
});
