import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-087a89f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hobbies-wrapper" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hobbies, (hobbie, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "circle-container",
        key: index
      }, [
        _createElementVNode("img", {
          class: "hobbies-icon",
          src: _ctx.getHobbies(hobbie)
        }, null, 8, _hoisted_2)
      ]))
    }), 128))
  ]))
}