
import { defineComponent } from "vue";
import sectionHeader from "@/components/SectionHeader.vue";
import backTop from "@/components/BackTopButton.vue";
// import titleText from "@/components/TitleAndText.vue";
import userImage from "@/components/UserImage.vue";
import communityProfile from "@/components/CommunityProfile.vue";
import * as userList from "@/data/user-data.json";

export default defineComponent({
  name: "community",
  components: {
    sectionHeader,
    backTop,
    // titleText,
    userImage,
    communityProfile,
  },
  data() {
    return {
      sectionHeaderText: this.$t("community-component.community"),
      descriptionText: [
        {
          title: this.$t("community-component.polyvalence"),
          text: this.$t("community-component.polyvalence-text"),
        },
        {
          title: this.$t("community-component.diversity"),
          text: this.$t("community-component.diversity-text"),
        },
      ],
      navOpen: false,
      userSelected: {},
      discordServerUrl: process.env.VUE_APP_DISCORD_LINK,
    };
  },
  methods: {
    updateUser(user: string) {
      this.navOpen = !this.navOpen;
      this.userSelected = user;
    },
  },
  computed: {
    randomList() {
      return userList.users.sort(function () {
        return 0.5 - Math.random();
      });
    },
  },
});
