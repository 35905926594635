
import { defineComponent } from "vue";

export default defineComponent({
  name: "backTop",
  methods: {
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
});
