import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26575681"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-icon-container" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "user-icon",
      src: _ctx.getAvatar,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('togglenav', _ctx.user)))
    }, null, 8, _hoisted_2)
  ]))
}