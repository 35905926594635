export default {
  "component-header": {
    "approach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IL NOSTRO APPROCCIO"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMMUNITY"])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLOG"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTATTACI"])}
  },
  "mission-component": {
    "talents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GROWING TALENTS"])},
    "digital-twin-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data driven talent development basato su Digital Twin"])},
    "digital-twin-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ogni nostro talento è rappresentato da un Digital Twin che si basa su dati raccolti da diverse fonti di dati. Attualmente stiamo raccogliendo dati da GitLab, Calendar, un sistema di gestione del tempo e dei progetti come Asana e app di messaggistica per il business come Slack, con la visione di integrare più fonti di dati in futuro."])},
    "mentoring-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentoring"])},
    "mentoring-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I nostri talent vengono formati attraverso progetti, concentrandosi sulle buone pratiche, l'eccellenza tecnica e il lavoro di squadra."])}
  },
  "back-to-top-component": {
    "back-to-top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna su"])}
  }
}