export default {
  "component-header": {
    "approach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOTRE APPROCHE"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMMUNITY"])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLOG"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACTEZ-NOUS"])}
  },
  "mission-component": {
    "talents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TALENTS EN DEVENIR"])},
    "digital-twin-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data driven talent development basé sur a Digital Twin"])},
    "digital-twin-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chacun de nos talents est représenté par un Digital Twin qui s'appuie sur des données recueillies à partir de différentes sources de données. Actuellement, nous recueillons des données à partir de GitLab, Calendar, un système de gestion du temps et de gestion de projet comme Asana et des applications de messagerie pour les entreprises comme Slack, avec la vision d'intégrer plus de sources de données à l'avenir."])},
    "mentoring-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentoring"])},
    "mentoring-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos talents sont encadrés par des projets, axés sur les bonnes pratiques, l'excellence technique et le travail d'équipe."])}
  },
  "back-to-top-component": {
    "back-to-top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour au début"])}
  }
}