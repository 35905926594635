
import { defineComponent } from "vue";
import { computed } from "@vue/reactivity";

export default defineComponent({
  name: "sectionHeader",
  props: {
    text: {
      required: true,
      type: String,
    },
    textColor: {
      type: String,
      required: false,
      default: "gray-90",
    },
  },
  setup(props) {
    const textClass = computed(() => {
      return `large-text-${props.textColor}`;
    });

    return { textClass };
  },
});
