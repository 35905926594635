
import { defineComponent } from "vue";
import headerHamburger from "@/components/MobileMenu.vue";

export interface HeaderSection {
  text: string;
  target: string;
  url: string;
}
export default defineComponent({
  name: "mainHeader",
  components: {
    headerHamburger,
  },
  data() {
    return {
      showNavbar: true,
      lastScrollPosition: 0,
      headerSections: [
        {
          text: this.$t("component-header.approach"),
          target: "_self",
          url: "#approach",
        },
        {
          text: this.$t("component-header.community"),
          target: "_self",
          url: "#community",
        },
        {
          text: this.$t("component-header.blog"),
          target: "_blank",
          url: process.env.VUE_APP_MEDIUM_LINK,
        },
        {
          text: this.$t("component-header.archive"),
          target: "_blank",
          url: process.env.VUE_APP_BLOG_LINK,
        },
        {
          text: this.$t("component-header.contacts"),
          target: "_self",
          url: "#contacts",
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      const startingPoint = 0;
      const offset = 100;
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < startingPoint) {
        return;
      }
      if (Math.abs(currentScrollPosition - this.lastScrollPosition) < offset) {
        return;
      }
      this.showNavbar = currentScrollPosition < this.lastScrollPosition;
      this.lastScrollPosition = currentScrollPosition;
    },
  },
});
