import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "contents" }
const _hoisted_2 = { class: "jumbotron-div" }
const _hoisted_3 = {
  class: "mission-div",
  id: "approach"
}
const _hoisted_4 = {
  class: "community-div",
  id: "community"
}
const _hoisted_5 = {
  class: "contacts-wrapper",
  id: "contacts"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mainJumbotron = _resolveComponent("mainJumbotron")!
  const _component_mission = _resolveComponent("mission")!
  const _component_community = _resolveComponent("community")!
  const _component_contacts = _resolveComponent("contacts")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_mainJumbotron)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_mission)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_community)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_contacts)
    ])
  ]))
}