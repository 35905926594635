
import { defineComponent, PropType } from "vue";
import { hobbies } from "@/models/HobbiesEnum";

export default defineComponent({
  name: "hobbie",
  props: {
    hobbies: {
      type: [] as PropType<string[]>,
      default: () => [],
    },
  },
  methods: {
    getHobbies(hobbie: string): string {
      if (hobbie in hobbies) {
        return hobbie.includes("http")
          ? hobbies[hobbie]
          : require("@/assets/images/" + hobbies[hobbie]);
      } else {
        return require("@/assets/images/placeholder-image.jpg");
      }
    },
  },
});
