export default {
  "component-header": {
    "approach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABOUT US"])},
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMMUNITY"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARCHIVE"])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BLOG"])},
    "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACT US"])}
  },
  "mission-component": {
    "talents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GROWING TALENTS"])},
    "digital-twin-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data driven talent development based on a Digital Twin"])},
    "digital-twin-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each of our talents is represented by a Digital Twin which is based on data gathered from different data sources. Currently we are gathering data from GitLab, Calendar, a time  management system and project management system like Asana and messaging apps for business like Slack, with a vision to integrate more data sources in the future."])},
    "mentoring-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentoring"])},
    "mentoring-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our talents are mentored through projects, focusing on good practices, technical excellence and team work."])},
    "connections-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community Connection"])},
    "connections-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join our community of remote developers for engaging discussions and valuable support. Connect with experts, share experiences, and collaborate on finding solutions to common challenges."])},
    "ideas-opportunities-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share Ideas and Opportunities"])},
    "ideas-opportunities-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join an active community where collaboration is the key. Connect with different people, exchange ideas, and catch exciting and new opportunities together."])},
    "learning-partners-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community learning Partners"])},
    "learning-partners-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will find other community members ready  to team up with you as you learn new skills or enhance existing ones. By sharing it with us, your learning journey will be more effective and enjoyable."])},
    "exchange-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["International Exchange"])},
    "exchange-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interact with developers from diverse backgrounds, broaden your horizons, and improve your professional and personal development."])}
  },
  "community-component": {
    "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMMUNITY"])},
    "polyvalence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polyvalence"])},
    "diversity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diversity"])},
    "polyvalence-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT Talents should become polivalent Experts in programming, communicating, being creative design thinker and self organized project member. Each talent has time dedicated to Design Thinking, communication, mentoring."])},
    "diversity-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our different experiences, backgrounds and interests foster an excellent environment for learning from each other."])},
    "meet-the-community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MEET THE COMMUNITY"])}
  },
  "back-to-top-component": {
    "back-to-top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to top"])}
  },
  "contact-component": {
    "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACT US"])},
    "last-motto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enabling talents to become polyvalent experts in a remote, connected world"])},
    "send-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send us a message and we’ll get back to you"])},
    "error-empty-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this field cannot be empty"])},
    "error-email-format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field must be a valid email"])},
    "email-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "text-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Write your message here..."])}
  },
  "community-profile": {
    "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])},
    "hbdi-model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HBDI Model"])},
    "lately-worked-with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LATELY WORKED WITH"])}
  },
  "footer": {
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright © 2024 "])},
    "like-a-coffee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Like a Coffee. "])},
    "copyright-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved. - "])}
  }
}