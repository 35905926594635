
import { defineComponent } from "vue";
import website from "@/views/Website.vue";

export default defineComponent({
  name: "App",
  components: {
    website,
  },
});
