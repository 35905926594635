
import { defineComponent } from "vue";

export default defineComponent({
  name: "textImage",
  props: {
    text: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    imageName: {
      type: String,
      required: true,
    },
    isReverse: {
      type: Boolean,
      default: false,
    },
  },
});
