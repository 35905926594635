enum Hobbies {
  SOCCER = "soccer.svg",
  VIDEOGAME = "videogame.svg",
  MUSIC = "music.svg",
  TRAVEL = "travelling.svg",
  SWIM = "swimming.svg",
  SKI = "skiing.svg",
  READ = "reading.svg",
  PADDLE = "paddling.svg",
  GYM = "gym.svg",
  GARDEN = "gardening.svg",
  FOOD = "food.svg",
  DRAW = "drawing.svg",
  CYCLE = "cycling.svg",
  COOK = "cooking.svg",
  COMMUNITY = "community.svg",
  CATS = "cats.svg",
  AUDIOBOOK = "audiobook.svg",
  TECHNOLOGY = "microchip.svg",
  DND = "dragon.svg",
}

export const hobbies: { [key: string]: Hobbies } = Hobbies;
