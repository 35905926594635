
import { defineComponent } from "vue";
import mainHeader from "@/components/Header.vue";
import contents from "@/components/Content.vue";
import footerComponent from "@/components/Footer.vue";

export default defineComponent({
  name: "website",
  components: {
    mainHeader,
    contents,
    footerComponent,
  },
});
