import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-container" }
const _hoisted_2 = { class: "title subtitle-text-orange" }
const _hoisted_3 = { class: "text" }
const _hoisted_4 = { class: "image-container" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['text-image-container', { reverse: _ctx.isReverse }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.text), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("img", {
        class: "image",
        src: require('@/assets/images/' + _ctx.imageName)
      }, null, 8, _hoisted_5)
    ])
  ], 2))
}