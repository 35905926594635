
import { defineComponent } from "vue";
import mainJumbotron from "@/components/Jumbotron.vue";
import mission from "@/components/Missions.vue";
import contacts from "@/components/Contacts.vue";
import community from "@/components/Community.vue";

export default defineComponent({
  name: "contents",
  components: {
    mainJumbotron,
    mission,
    contacts,
    community,
  },
});
