
import { defineComponent, computed } from "vue";
import { userInterface } from "@/models/UserInteface";
import hobbie from "@/components/Hobbies.vue";

export default defineComponent({
  name: "communityProfile",
  components: {
    hobbie,
  },
  props: {
    open: {
      type: Boolean,
    },
    user: {
      type: Object as () => userInterface,
      required: true,
    },
  },
  methods: {
    getImg(imgsrc: string): string {
      if (!imgsrc) {
        return require("@/assets/images/placeholder-image.jpg");
      }
      return imgsrc.includes("http")
        ? imgsrc
        : require("@/assets/images/users/" + imgsrc);
    },
  },
  setup(props) {
    const openOverlay = computed(() => {
      props.open
        ? document.body.classList.add("disable-scroll")
        : document.body.classList.remove("disable-scroll");
      return props.open;
    });
    return { openOverlay };
  },
});
